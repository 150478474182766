import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import React, { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Icon } from '@iconify/react';

import ipiechart from "assets/svg/PieChart.svg";
import icard from "assets/svg/card.svg";
import ihistory from "assets/svg/History.svg";
import ishop2 from "assets/svg/Shop2.svg";
import itag from "assets/svg/tag.svg";
import iusercircle from "assets/svg/UserCircle.svg";
import iuserid from "assets/svg/UserId.svg";
import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { date, months, years, tourist, touristinitial } from "mocks/tourist";
import { getGender } from "../../../redux/handlers/master/gender";
import { getNationality } from "../../../redux/handlers/master/nationality";
import { getPrefix } from "../../../redux/handlers/master/prefix";
import { getProvince } from "../../../redux/handlers/master/province";
import { ModalSuccess } from "components/layouts/ModalSuccess";
import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getUserTravellerById, createUserTraveller, updateUserTraveller } from "../../../redux/handlers/master/userTraveller";
import { data } from "jquery";

export function TouristInformation() {
    let { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userTravellerReducer = useAppSelector((state) => state.userTravellerReducer);
    const [openModalSuccess, setOpenModalSuccess] = useState(false)
    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);
    const schema = yup.object().shape({
        prefix_name_id: yup.string().required(),
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        date: yup.string().required(),
        month: yup.string().required(),
        year: yup.string().required(),
        gender_id: yup.string().required(),
        // phone_number: yup.string().required(),
    });
    const countryList = [
        {
            value: 'ประเทศไทย',
            label: 'ประเทศไทย',
        }
    ];
    const [genderList, setGenderList] = useState([])
    const genderReducer = useAppSelector((state) => state.genderReducer);
    const gender = [];
    const [nationalityList, setNationalityList] = useState([])
    const nationalityReducer = useAppSelector((state) => state.nationalityReducer);
    const nationality = [];
    const [prefixList, setPrefixList] = useState([])
    const prefixReducer = useAppSelector((state) => state.prefixReducer);
    const prefix = [];
    const provinceReducer = useAppSelector((state) => state.provinceReducer);
    const [provinceList, setProvinceList] = useState([])
    const provinces = []
    const [formdata, setFormdata] = useState(null)
    const initialFormValues = formdata || {
        id: null,
        code: null,
        gender_id: null,
        province_id: null,
        prefix_name_id: null,
        line_user_id: null,
        image_path: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        verify_phone_code: null,
        birth_date: null,
        nationality_id: null,
        pdpa: null,
        is_upload_image: null,
        is_status: null,
        date: null,
        month: null,
        year: null
    };
    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            console.log(values);
            const formData = new FormData();

            if (image) {
                formData.append('image_path', image);
                formData.append('is_upload_image', true);
            } else {
                formData.append('is_upload_image', false);
            }

            if (values.gender_id) {
                formData.append('gender_id', values.gender_id);
            }

            if (values.province_id) {
                formData.append('province_id', values.province_id);
            }

            if (values.prefix_name_id) {
                formData.append('prefix_name_id', values.prefix_name_id);
            }

            if (values.nationality_id) {
                formData.append('nationality_id', values.nationality_id);
            }

            if (values.date && values.month && values.year) {
                const monthStr = values.month.toString().padStart(2, '0');
                const dayStr = values.date.toString().padStart(2, '0');
                // Construct the date string in the desired format
                const dateStr = `${values.year}-${monthStr}-${dayStr}T00:00:00.000Z`;

                formData.append('birth_date', dateStr);
            }

            formData.append('first_name', values.first_name);
            formData.append('last_name', values.last_name);
            formData.append('phone_number', values.phone_number);
            formData.append('is_status', values.is_status ? values.is_status : false);
            formData.append('pdpa', true);
            formData.append('line_user_id', values.line_user_id);

            if (formdata && formdata.id) {
                dispatch(updateUserTraveller(formData, formdata.id))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenModalSuccess(true)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating user traveller', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                dispatch(createUserTraveller(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenModalSuccess(true)
                            navigate(`/tourist`)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating user traveller', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }

        } catch (error) {
            console.error('Error submitting the form', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        dispatch(getGender());
        dispatch(getPrefix());
        dispatch(getNationality());
        dispatch(getProvince());
    }, []);

    useEffect(() => {
        if (genderReducer.data) {
            setGenderList(genderReducer.data.data);
        }
    }, [genderReducer]);


    if (genderList && genderList.length > 0) {
        for (const element of genderList) {
            gender.push({
                label: element.name,
                value: element.id
            })
        }
    }

    useEffect(() => {
        if (nationalityReducer.data) {
            setNationalityList(nationalityReducer.data.data);
        }
    }, [nationalityReducer]);

    if (nationalityList && nationalityList.length > 0) {
        for (const element of nationalityList) {
            nationality.push({
                label: element.name,
                value: element.id
            })
        }
    }

    useEffect(() => {
        if (prefixReducer.data) {
            setPrefixList(prefixReducer.data.data);
        }
    }, [prefixReducer]);

    if (prefixList && prefixList.length > 0) {
        for (const element of prefixList) {
            prefix.push({
                label: element.name,
                value: element.id
            })
        }
    }

    useEffect(() => {
        if (provinceReducer.data) {
            setProvinceList(provinceReducer.data.data)
        }
    }, [provinceReducer]);

    if (provinceList && provinceList.length > 0) {
        for (const element of provinceList) {
            provinces.push({
                label: element.name_th,
                value: element.id
            })
        }
    }

    useEffect(() => {
        if (id) {
            dispatch(getUserTravellerById(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (userTravellerReducer.data) {
            const responseData = userTravellerReducer.data.data;
            const date = new Date(responseData.birth_date);
            const day = date.getUTCDate(); // Day of the month
            const month = date.getUTCMonth() + 1; // Months are zero-indexed, so add 1
            const year = date.getUTCFullYear();
            const data = {
                ...responseData,
                date: day,
                month: month,
                year: year
            };
            console.log(data);
            setFormdata(data);
        }
    }, [userTravellerReducer.data]);

    // set month value to number instead name
    for (const key in months) {
        if (Object.prototype.hasOwnProperty.call(months, key)) {
            const element = months[key];
            element.value = key
        }
    }

    return (
        <>
            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px]">
                <div className="text-[#31A7DE] font-bold text-lg mb-5">ข้อมูลส่วนตัว</div>
                <div className="text-[#4C4E64]/[0.68]  text-base mb-5">ภาพโปรไฟล์</div>
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="flex items-center mb-9">
                                <Avatar
                                    alt=""
                                    src={image ? URL.createObjectURL(image) : `${values.image_path}`}
                                    sx={{ width: 101, height: 101 }}
                                />
                                <div className="ml-6">
                                    <Button
                                        variant="contained"
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileRef.current.value = ""
                                            setImage(null)
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 5MB</div>
                                    <input
                                        ref={inputFileRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {
                                            const newImage = event.target?.files?.[0];
                                            if (newImage) {
                                                const maxSizeInMB = 5;
                                                if (newImage.size > maxSizeInMB * 1024 * 1024) {
                                                    alert(`แสดงว่าขนาดไฟล์ของคุณมีขนาดเกินกำหนด`);
                                                    return; // Exit the function to prevent further processing
                                                }
                                                setImage(newImage);  // เก็บเป็นไฟล์แทน URL
                                                setFieldValue('image_path', newImage); // เก็บไฟล์ไว้ใน form data
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 gap-7 mb-6">
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        disabled
                                        label="รหัสนักท่องเที่ยว"
                                        value={values.code}
                                        name='code'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.code}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        select
                                        label="คำนำหน้า"
                                        name='prefix_name_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.prefix_name_id}
                                        error={!!errors.prefix_name_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {prefix.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        label="ชื่อ"
                                        name='first_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                        error={!!errors.first_name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        label="นามสกุล"
                                        name='last_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                        error={!!errors.last_name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        select
                                        label="วันที่"
                                        name='date'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.date}
                                        error={!!errors.date}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {date.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        select
                                        label="เดือนเกิด"
                                        name='month'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.month}
                                        error={!!errors.month}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {months.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        select
                                        label="ปีเกิด"
                                        name='year'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.year}
                                        error={!!errors.year}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {years().map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        select
                                        label="เพศ"
                                        name='gender_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.gender_id}
                                        error={!!errors.gender_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {gender.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        select
                                        label="สัญชาติ"
                                        name='nationality_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nationality_id}
                                        error={!!errors.nationality_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {nationality.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        select
                                        label="จังหวัด"
                                        name='province_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.province_id}
                                        error={!!errors.province_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {provinces.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                {/* <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        select
                                        label="ประเทศ"
                                        name='country'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.country}
                                        error={!!errors.country}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {countryList.map((option) => (
                                            <MenuItem key={option.label} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div> */}
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        label="เบอร์โทร"
                                        name='phone_number'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone_number}
                                        error={!!errors.phone_number}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        label="UID LINE"
                                        name='line_user_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.line_user_id}
                                        error={!!errors.line_user_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="mb-6">
                                <FormControl>
                                    <div>สถานะ</div>
                                    <RadioGroup
                                        row
                                        aria-labelledby=""
                                        name="is_status"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.is_status} // Ensure values.is_status has a default value
                                        error={!!errors.is_status}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="เปิดใช้งาน" />
                                        <FormControlLabel value="false" control={<Radio />} label="ปิดใช้งาน" />
                                    </RadioGroup>
                                </FormControl>

                            </div> */}
                            <div className="flex justify-end mb-7">
                                <Button
                                    variant="outlined"
                                    className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                    href="/tourist"
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    variant="contained"
                                    className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
                <ModalSuccess setOpen={setOpenModalSuccess} open={openModalSuccess} />
            </div>
        </>
    )
}


