import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import Avatar from '@mui/material/Avatar';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { prefix, date, months, years, gender, nationality, user, userinitial, permissions } from "mocks/user";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Checkbox from '@mui/material/Checkbox';

import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { QuillCustom } from "components/fragments/QuillCustom";
import { ModalConfirm } from "components/layouts/ModalConfirm";
import { ModalDeleteSuccess } from 'components/layouts/ModalDeleteSuccess';
import { ModalSuccess } from 'components/layouts/ModalSuccess';
import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getSurveyById, createSurvey, updateSurvey } from "../../../redux/handlers/master/survey";
import { getSurveyList, createSurveyList, updateSurveyList, deleteSurveyList } from "../../../redux/handlers/master/surveyList";
import { getSurveyListType } from "../../../redux/handlers/master/surveyListType";

export function SurveyManage() {
    let { surveyID } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const surveyReducer = useAppSelector((state) => state.surveyReducer);
    const [openModalSuccess, setOpenModalSuccess] = useState(false)
    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);
    const [formdata, setFormdata] = useState(null)
    const initialFormValues = formdata || {
        id: null,
        code: null,
        name: null,
        image_path_notification: null,
        title_notification: null,
        detail_notification: null,
        is_status: null,
    };
    const schema = yup.object().shape({
        name: yup.string().required(),
        title_notification: yup.string().required(),
        detail_notification: yup.string().required(),
        is_status: yup.boolean().required(),
    });
    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            let formData = new FormData()

            if (image) {
                formData.append('image_path_notification', image);
            }

            formData.append('name', values.name)
            formData.append('title_notification', values.title_notification)
            formData.append('detail_notification', values.detail_notification)
            formData.append('is_status', values.is_status)

            if (formdata && formdata.id) {
                dispatch(updateSurvey(formData, formdata.id))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenModalSuccess(true)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating survey', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                dispatch(createSurvey(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenModalSuccess(true)
                            dispatch(getSurveyById(response.data.id))
                            navigate(`/setting/survey/manage/${response.data.id}`)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating survey', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        } catch (error) {
            console.error('Error submitting the form', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (surveyID) {
            dispatch(getSurveyById(surveyID));
        }
    }, [surveyID, dispatch]);

    useEffect(() => {
        if (surveyReducer.data) {
            const data = surveyReducer.data.data;
            setFormdata(data);
        }
    }, [surveyReducer.data]);

    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[0.87] text-2xl my-5 font-medium">สร้างแบบสำรวจ</div>
            <Formik
                initialValues={initialFormValues}
                validationSchema={schema}
                enableReinitialize
                onSubmit={handleFormSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit} >
                        <div className="bg-white  rounded-[10px] drop-shadow pt-6 pb-3 mb-4 p-[25px] ">
                            <div className="text-[#31A7DE]  font-medium text-xl mb-5">รูปภาพแบบสำรวจ</div>

                            <div className="text-[#31A7DE]  font-medium text-xl mb-5">แบบสำรวจ</div>
                            <div className="grid grid-cols-12 gap-7 mb-6">
                                <div className="lg:col-span-3 col-span-12">
                                    <TextField
                                        label="รหัสแบบสำรวจ"
                                        name='code'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.code}
                                        error={!!errors.code}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="ชื่อแบบสอบถาม"
                                        name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        error={!!errors.name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                {/* <div className="lg:col-span-3 col-span-12">
                                    <TextField

                                        select
                                        label="ประเภท"
                                        name='type'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.type}

                                        error={!!errors.type}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {type && type.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div> */}
                                <div className="col-span-12">
                                    <FormControl>
                                        <div>สถานะ</div>
                                        <RadioGroup
                                            row
                                            aria-labelledby=""
                                            name="is_status"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.is_status}
                                            error={!!errors.is_status}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="เปิดใช้งาน" />
                                            <FormControlLabel value="false" control={<Radio />} label="ปิดใช้งาน" />
                                        </RadioGroup>
                                    </FormControl>
                                    {/* { !!errors.is_status &&
                                        <p className="text-[#d32f2f] mt-1">กรุณาระบุสถานะ</p>
                                    } */}
                                </div>

                                <div className="lg:col-span-12 col-span-12">
                                    <div className="flex items-center mb-9">
                                        {
                                            image || values.image_path_notification ? (
                                                <img src={image ? URL.createObjectURL(image) : values.image_path_notification} className="w-[200px] h-[185px] object-cover rounded-lg " />
                                            ) : (
                                                <div className="w-[200px] h-[185px] bg-[#F2F2F2] rounded-lg flex items-center justify-center">
                                                    <CameraAltIcon className="text-[#BDBDBD] text-[50px]" />
                                                </div>
                                            )
                                        }
                                        <div className="ml-6">
                                            <Button
                                                variant="contained"
                                                className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                                onClick={() => inputFileRef.current.click()}
                                            >
                                                อัปโหลดรูปภาพ
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                                onClick={() => {
                                                    inputFileRef.current.value = ""
                                                    setImage(null)
                                                    setFieldValue('image', null)
                                                }}
                                            >
                                                ลบ
                                            </Button>
                                            <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดแนะนำ 200x200 Pixels และขนาดไฟล์ไม่เกิน 5 MB</div>
                                            <input
                                                ref={inputFileRef}
                                                accept="image/*"
                                                hidden
                                                id="avatar-image-upload"
                                                type="file"
                                                onChange={(event) => {
                                                    const newImage = event.target?.files?.[0];

                                                    if (newImage) {
                                                        const maxSizeInMB = 5;
                                                        if (newImage.size > maxSizeInMB * 1024 * 1024) {
                                                            alert(`แสดงว่าขนาดไฟล์ของคุณมีขนาดเกินกำหนด`);
                                                            return; // Exit the function to prevent further processing
                                                        }
                                                        setImage(newImage)
                                                        setFieldValue('image', newImage)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:col-span-5 col-span-12">
                                    <div className="text-[#31A7DE]  font-medium text-xl mb-5">หัวข้อแจ้งเตือนนักท่องเที่ยว</div>
                                    <div className="">
                                        <QuillCustom
                                            name="title_notification"
                                            value={values.title_notification}
                                            error={!!errors.title_notification}
                                            onChange={(value) => {
                                                setFieldValue('title_notification', value)
                                            }}
                                            placeholder="ระบุหัวข้อแจ้งเตือนนักท่องเที่ยว"
                                        />
                                        {/* { !!errors.title_notification &&
                                            <p className="text-[#d32f2f] mt-1">กรุณาระบุหัวข้อแจ้งเตือนนักท่องเที่ยว</p>
                                        } */}
                                    </div>
                                </div>
                                <div className="lg:col-span-5 col-span-12">
                                    <div className="text-[#31A7DE]  font-medium text-xl mb-5">รายละเอียดแจ้งเตือนนักท่องเที่ยว</div>
                                    <div className="">
                                        <QuillCustom
                                            name="detail_notification"
                                            value={values.detail_notification}
                                            error={!!errors.detail_notification}
                                            onChange={(value) => {
                                                setFieldValue('detail_notification', value)
                                            }}
                                            placeholder="ระบุรายละเอียดแจ้งเตือนนักท่องเที่ยว"
                                        />
                                        {/* { !!errors.detail_notification &&
                                            <p className="text-[#d32f2f] mt-1">กรุณาระบุรายละเอียดแจ้งเตือนนักท่องเที่ยว</p>
                                        } */}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end mb-7">
                                <Button
                                    variant="outlined"
                                    className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                    href="/setting/survey"
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    variant="contained"
                                    className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
            {(formdata && formdata?.id) &&
                <SurveyListForm surveyID={formdata.id} setOpenModalSuccess={setOpenModalSuccess} />
            }
            <ModalSuccess setOpen={setOpenModalSuccess} open={openModalSuccess} />
        </>
    )
}

const SurveyListForm = ({ surveyID, setOpenModalSuccess }) => {
    const dispatch = useAppDispatch();
    const surveyListReducer = useAppSelector((state) => state.surveyListReducer);
    const surveyListTypeReducer = useAppSelector((state) => state.surveyListTypeReducer);
    const [openModalDeleteSuccess, setOpenModalDeleteSuccess] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [removeIndex, setRemoveIndex] = useState(null);
    const [renderArrayHelpers, setRenderArrayHelpers] = useState(null);
    const [surveyListTypeList, setSurveyListTypeList] = useState([])
    const [surveyList, setSurveyList] = useState([])
    const surveyListType = [];
    const [form, setForm] = useState(null);
    const schema = yup.object().shape({
        surveys: yup.array()
            .of(
                yup.object().shape({
                    name: yup.string().required('Required'),
                    type: yup.string().required('Required'),
                })
            )
            .required()
    });
    const deleteData = () => {
        if (deleteId) {
            dispatch(deleteSurveyList(deleteId))
                .then((response) => {
                    if (response.message === "success") {
                        renderArrayHelpers.remove(removeIndex)
                        setRenderArrayHelpers(null)
                        setDeleteId(null)
                        setOpenConfirm(false)
                        setOpenModalDeleteSuccess(true)
                    }
                })
                .catch((error) => {
                    console.error('Error delete survey', error);
                })
        } else {
            setOpenConfirm(false)
            renderArrayHelpers.remove(removeIndex)
            setRenderArrayHelpers(null)
        }
    }
    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = []

            for (const item of values.surveys) {
                formData.push({
                    id: item.id || null,
                    survey_id: surveyID,
                    name: item.name,
                    survey_list_type_id: item.type
                })
            }

            if (surveyList.length) {
                dispatch(updateSurveyList(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            dispatch(getSurveyList(surveyID))
                            setOpenModalSuccess(true)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating survey list', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                dispatch(createSurveyList(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            dispatch(getSurveyList(surveyID))
                            setOpenModalSuccess(true)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating survey list', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        } catch (error) {
            console.error('Error submitting the form', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        dispatch(getSurveyListType());
    }, []);

    useEffect(() => {
        if (surveyListTypeReducer.data) {
            setSurveyListTypeList(surveyListTypeReducer.data.data);
        }
    }, [surveyListTypeReducer]);

    if (surveyListTypeList && surveyListTypeList.length > 0) {
        for (const element of surveyListTypeList) {
            surveyListType.push({
                label: element.name,
                value: element.id
            })
        }
    }

    useEffect(() => {
        dispatch(getSurveyList(surveyID));
    }, [surveyID]);

    useEffect(() => {
        let surveyListData = {
            surveys: []
        }

        if (surveyListReducer.data && surveyListReducer.data.data.length) {
            setSurveyList(surveyListReducer.data.data)
            surveyListData.surveys = surveyListReducer.data.data.map(element => ({
                id: element?.id,
                name: element?.name,
                type: element?.survey_list_type_id
            }))
        }

        setForm(surveyListData)
    }, [surveyListReducer.data]);

    return (
        <>
            <div className="bg-white  rounded-[10px] drop-shadow pt-6 pb-3 mt-7 mb-4 p-[25px] ">
                <div className="text-[#31A7DE]  font-medium text-xl mb-5">สร้างลิสต์คำถาม</div>
                <Formik
                    enableReinitialize
                    initialValues={form}
                    validationSchema={schema}
                    onSubmit={handleFormSubmit}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        setValues,
                        isInvalid,
                        isValid,
                        isSubmitting
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-7">
                                <FieldArray
                                    name="surveys"
                                    render={arrayHelpers => (
                                        <div>
                                            {values && values.surveys && values.surveys.map((item, index) => (
                                                <div className="mb-3" key={index}>
                                                    <div className="mt-3">
                                                        <div className='py-2'>
                                                            <Stack direction="row" spacing={2} sx={{
                                                                alignItems: "center",
                                                            }}>
                                                                <div>
                                                                    <TextField
                                                                        id=""
                                                                        label="ชื่อคำถาม"
                                                                        className='w-[450px]'
                                                                        InputProps={{ sx: { borderRadius: '10px' } }}
                                                                        onChange={(event, value) => {
                                                                            setFieldValue(`surveys[${index}].name`, event.target.value)
                                                                        }}
                                                                        value={values.surveys[index].name}
                                                                        error={errors.surveys && errors.surveys[index] && !!errors.surveys[index].name}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <TextField
                                                                        id=""
                                                                        select
                                                                        label="ประเภทการตอบแบบสำรวจ"
                                                                        className='w-[300px]'
                                                                        InputProps={{ sx: { borderRadius: '10px' } }}
                                                                        value={values.surveys[index].type}
                                                                        error={errors.surveys && errors.surveys[index] && !!errors.surveys[index].type}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`surveys[${index}].type`, e.target.value)

                                                                        }}
                                                                    >
                                                                        {surveyListType.map((option) => (
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </div>
                                                                <div>
                                                                    <IconButton className="w-[30px] mr-3 h-[30px] p-1" style={{
                                                                        background: '#F95057',
                                                                    }}
                                                                        onClick={() => {
                                                                            setRenderArrayHelpers(arrayHelpers)
                                                                            setRemoveIndex(index)
                                                                            setDeleteId(item.id)
                                                                            setOpenConfirm(true)
                                                                            // arrayHelpers.remove(index)
                                                                        }}
                                                                    >
                                                                        <img src={ic_trash} alt="" />
                                                                    </IconButton>
                                                                </div>
                                                            </Stack>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                            <Button
                                                variant="contained"
                                                className=" bg-[#FFF] px-0 shadow-none text-[#FF9900] rounded-[8px] text-base  h-[44px] mr-3"

                                                onClick={() => arrayHelpers.push({
                                                    "name": "",
                                                    "type": ""
                                                })}
                                            >
                                                <AddIcon className="mr-2" />  เพิ่มรายการ
                                            </Button>
                                        </div>
                                    )}
                                />
                                {
                                    values && values.surveys && values.surveys.length > 0 ? (
                                        <>
                                            <div className='text-end mt-[40px]'>
                                                <Button
                                                    variant="outlined"
                                                    className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                                    href="/setting/survey"
                                                >
                                                    ยกเลิก
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                                    type="submit"
                                                >
                                                    บันทึก
                                                </Button>
                                            </div>
                                        </>
                                    ) : ''
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <ModalDeleteSuccess open={openModalDeleteSuccess} setOpen={setOpenModalDeleteSuccess} />
            <ModalConfirm
                open={openConfirm}
                setOpen={setOpenConfirm}
                message={"คุณต้องการลบข้อมูลนี้ใช่หรือไม่"}
                confirm={() => deleteData()}
            />
        </>
    )
}

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        ตั้งค่า
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
        ตั้งค่าแบบสำรวจ
    </Typography>
];

